<script>
// import Layout from "../../layouts/main";
import json from './data.json';
// import Switches from "vue-switches";

/**
 * Dashboard component
 */
export default {
  components: {
    // Switches,
  },
  data() {
    return {
      title: "Home",
      items: [
        {
          text: "Zawiyah ",
        },
        {
          text: "Home",
          active: true,
        },
      ],
      questions: [],
      level: 2,
      json: json,
      checked: false,
      language: 'english',
      showMenu: false
    };
  },
  watch: {
    checked(val) {
      if(val) {
        this.language = 'urdu'
      } else {
        this.language = 'english'
      }
    }
  },
  computed: {
    userData() {
      return JSON.parse(localStorage.getItem('user_info'))
    },
  },
  methods: {},
  mounted() {
    // this.json = data
    console.log(this.json)
  },
};
</script>

<template>
  <!-- <Layout> -->
    <body id="page-top">
      <!-- Navigation-->
      <nav
        class="navbar navbar-expand-lg navbar-light fixed-top shadow-sm"
        id="mainNav"
      >
        <div class="container px-5">
          <a class="navbar-brand fw-bold" href="#page-top">{{json.title[language]}}</a>
          <!-- {{json}} -->
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
            @click="showMenu = !showMenu"
          >
            Menu
            <i class="bi-list"></i>
          </button>
          <div class="collapse navbar-collapse" id="navbarResponsive" :class="{'show': showMenu}">
            <ul class="navbar-nav ms-auto my-3 my-lg-0">
              <li class="nav-item">
                <a class="nav-link me-lg-3" href="/">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link me-lg-3" href="/#/team">Team</a>
              </li>
              <li class="nav-item">
                <a class="nav-link me-lg-3" href="/#/wellness-club">Wellness Club</a>
              </li>
              <li class="nav-item">
                <a class="nav-link me-lg-3" href="/#/resources">Resources</a>
              </li>
            </ul>
            <div class="d-flex px-3 mr-3 p-2 border rounded-3" :class="{'mb-3': showMenu}">
              <span>English</span>
              <b-form-checkbox v-model="checked" switch class="mx-2">
              </b-form-checkbox>
              <span>Urdu</span>
            </div>
            <a v-if="userData && userData.id && userData.role == 'user'" class="btn btn-primary rounded-pill px-3 mb-2 mb-lg-0" href="/#/dashboard">Dashboard</a>
            <a v-else-if="userData && userData.id && userData.role == 'admin'" class="btn btn-primary rounded-pill px-3 mb-2 mb-lg-0" href="/#/admin">Dashboard</a>
            <a v-else class="btn btn-primary rounded-pill px-3 mb-2 mb-lg-0" href="/#/login">Login</a>
            <!-- <div class="d-flex">
              <span>English</span>
              <b-form-checkbox v-model="checked" switch class="mx-2">
              </b-form-checkbox>
              <span>Urdu</span>
            </div> -->

          </div>
        </div>
      </nav>
      <!-- Mashead header-->
      <header class="masthead" :dir="language == 'english' ? 'ltr': 'rtl'">
        <div class="container px-5">
          <div class="row gx-5 align-items-center">
            <div class="col-lg-6">
              <!-- Mashead text and app badges-->
              <div class="mb-5 mb-lg-0">
                <h1 class="display-1 lh-1 mb-3">
                  {{json.head.tag[language]}}
                </h1>
                <p class="lead fw-normal text-muted mb-5">
                  {{json.head.second[language]}}
                </p>
                <!-- <div class="d-flex flex-column flex-lg-row align-items-center">
                  <a class="me-lg-3 mb-4 mb-lg-0" href="#!"
                    ><img
                      class="app-badge"
                      src="assets/img/google-play-badge.svg"
                      alt="..."
                  /></a>
                  <a href="#!"
                    ><img
                      class="app-badge"
                      src="assets/img/app-store-badge.svg"
                      alt="..."
                  /></a>
                </div> -->
              </div>
            </div>
            <div class="col-lg-6">
                      <img class="rounded-circle circle" src="https://images.unsplash.com/photo-1573483883644-d0b4b55eb25d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"
                      style="max-width: 100%; height: 100%"/>
              
            </div>
          </div>
        </div>
      </header>
      <!-- Quote/testimonial aside-->
      <aside class="text-center bg-gradient-primary-to-secondary">
        <div class="container px-5">
          <div class="row gx-5 justify-content-center">
            <div class="col-xl-8">
              <div class="h2 fs-1 text-white mb-4">
                {{json.aboutMission.valueDescription[language]}}
              </div>
              <!-- <img
                src="assets/img/tnw-logo.svg"
                alt="..."
                style="height: 3rem"
              /> -->
            </div>
          </div>
        </div>
      </aside>
      <!-- App features section-->
      <section id="features" :dir="language == 'english' ? 'ltr': 'rtl'">
        <div class="container px-5">
          <div class="row gx-5 align-items-center">
            <div class="col-lg-6 order-lg-1 mb-5 mb-lg-0">
              <div class="container-fluid px-5">
                <div class="row gx-5">
                  <div class="col-md-12 mb-5">
                    <!-- Feature item-->
                    <div class="">
                      <i
                        class="bi-phone icon-feature text-gradient d-block mb-3"
                      ></i>
                      <h3 class="font-alt">{{json.aboutMission.aboutTitle[language]}}</h3>
                      <p class="text-muted mb-0">
                        {{json.aboutMission.aboutDescription[language]}}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 mb-5">
                    <!-- Feature item-->
                    <div class="">
                      <i
                        class="bi-gift icon-feature text-gradient d-block mb-3"
                      ></i>
                      <h3 class="font-alt">{{json.aboutMission.missionTitle[language]}}</h3>
                      <p class="text-muted mb-0">
                        {{json.aboutMission.missionDescription[language]}}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 mb-5 mb-md-0">
                    <!-- Feature item-->
                    <div class="">
                      <i
                        class="bi-gift icon-feature text-gradient d-block mb-3"
                      ></i>
                      <h3 class="font-alt">{{json.aboutMission.visionTitle[language]}}</h3>
                      <p class="text-muted mb-0">
                        {{json.aboutMission.visionDescription[language]}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 order-lg-0">
              <!-- Features section device mockup-->
                      <img class="" src="https://images.unsplash.com/photo-1589462135796-2b46e4bdd7fe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80"
                      style="max-width: 100%; height: 100%"/>
            </div>
          </div>
        </div>
      </section>
      <!-- Basic features section-->
      <section class="bg-light" :dir="language == 'english' ? 'ltr': 'rtl'">
        <div class="container px-5">
          <div
            class="
              row
              gx-5
              align-items-center
              justify-content-center justify-content-lg-between
            "
          >
            <div class="col-12 col-lg-5">
              <h2 class="display-4 lh-1 mb-4">{{json.about.name[language]}}</h2>
              <h2 class="display-6 lh-1 mb-4 text-muted">{{json.about.position[language]}}</h2>
              <p class="lead fw-normal text-muted mb-5 mb-lg-0">
              {{json.about.detail[language]}}              
              </p>
            </div>
            <div class="col-sm-8 col-md-6">
              <div class="px-5 px-sm-0">
                <img
                  class="img-fluid rounded-circle"
                  src="https://i.ibb.co/7bHQw7r/Shifaqat-ali-shaikh-main-1.jpg"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Call to action section-->
      <section class="cta">
        <div class="cta-content">
          <div class="container px-5">
            <h2 class="text-white display-1 lh-1 mb-4">
              Stop waiting.
              <br />
              Join Now.
            </h2>
            <a
              class="btn btn-outline-light py-3 px-4 rounded-pill"
              href="https://startbootstrap.com/theme/new-age"
              target="_blank"
              >Subscribe</a
            >
          </div>
        </div>
      </section>
      <!-- App badge section-->
      <section class="bg-gradient-primary-to-secondary" id="download">
        <div class="container px-5">
          <h2 class="text-center text-white font-alt mb-4">Get in Touch!</h2>
          <div>
            <h4 class="text-center text-white font-alt">+92 321 4609800</h4>
            <h4 class="text-center text-white font-alt">shafaqatali0033@gmail.com</h4>
          </div>
          <!-- <div
            class="
              d-flex
              flex-column flex-lg-row
              align-items-center
              justify-content-center
            "
          >
            <a class="me-lg-3 mb-4 mb-lg-0" href="#!"
              ><img
                class="app-badge"
                src="assets/img/google-play-badge.svg"
                alt="..."
            /></a>
            <a href="#!"
              ><img
                class="app-badge"
                src="assets/img/app-store-badge.svg"
                alt="..."
            /></a>
          </div> -->
        </div>
      </section>
      <!-- Footer-->
      <footer class="bg-black text-center py-5">
        <div class="container px-5">
          <div class="text-white-50 small">
            <div class="mb-2">
              &copy; Zawiyah Foundation Website 2022. All Rights Reserved.
            </div>
            <!-- <a href="#">Privacy</a>
            <span class="mx-1">&middot;</span>
            <a href="#">Terms</a>
            <span class="mx-1">&middot;</span>
            <a href="#">FAQ</a> -->
          </div>
        </div>
      </footer>
      <!-- Feedback Modal-->
      <div
        class="modal fade"
        id="feedbackModal"
        tabindex="-1"
        aria-labelledby="feedbackModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header bg-gradient-primary-to-secondary p-4">
              <h5
                class="modal-title font-alt text-white"
                id="feedbackModalLabel"
              >
                Send feedback
              </h5>
              <button
                class="btn-close btn-close-white"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body border-0 p-4">
              <!-- * * * * * * * * * * * * * * *-->
              <!-- * * SB Forms Contact Form * *-->
              <!-- * * * * * * * * * * * * * * *-->
              <!-- This form is pre-integrated with SB Forms.-->
              <!-- To make this form functional, sign up at-->
              <!-- https://startbootstrap.com/solution/contact-forms-->
              <!-- to get an API token!-->
              <form id="contactForm" data-sb-form-api-token="API_TOKEN">
                <!-- Name input-->
                <div class="form-floating mb-3">
                  <input
                    class="form-control"
                    id="name"
                    type="text"
                    placeholder="Enter your name..."
                    data-sb-validations="required"
                  />
                  <label for="name">Full name</label>
                  <div
                    class="invalid-feedback"
                    data-sb-feedback="name:required"
                  >
                    A name is required.
                  </div>
                </div>
                <!-- Email address input-->
                <div class="form-floating mb-3">
                  <input
                    class="form-control"
                    id="email"
                    type="email"
                    placeholder="name@example.com"
                    data-sb-validations="required,email"
                  />
                  <label for="email">Email address</label>
                  <div
                    class="invalid-feedback"
                    data-sb-feedback="email:required"
                  >
                    An email is required.
                  </div>
                  <div class="invalid-feedback" data-sb-feedback="email:email">
                    Email is not valid.
                  </div>
                </div>
                <!-- Phone number input-->
                <div class="form-floating mb-3">
                  <input
                    class="form-control"
                    id="phone"
                    type="tel"
                    placeholder="(123) 456-7890"
                    data-sb-validations="required"
                  />
                  <label for="phone">Phone number</label>
                  <div
                    class="invalid-feedback"
                    data-sb-feedback="phone:required"
                  >
                    A phone number is required.
                  </div>
                </div>
                <!-- Message input-->
                <div class="form-floating mb-3">
                  <textarea
                    class="form-control"
                    id="message"
                    type="text"
                    placeholder="Enter your message here..."
                    style="height: 10rem"
                    data-sb-validations="required"
                  ></textarea>
                  <label for="message">Message</label>
                  <div
                    class="invalid-feedback"
                    data-sb-feedback="message:required"
                  >
                    A message is required.
                  </div>
                </div>
                <!-- Submit success message-->
                <!---->
                <!-- This is what your users will see when the form-->
                <!-- has successfully submitted-->
                <div class="d-none" id="submitSuccessMessage">
                  <div class="text-center mb-3">
                    <div class="fw-bolder">Form submission successful!</div>
                    To activate this form, sign up at
                    <br />
                    <a href="https://startbootstrap.com/solution/contact-forms"
                      >https://startbootstrap.com/solution/contact-forms</a
                    >
                  </div>
                </div>
                <!-- Submit error message-->
                <!---->
                <!-- This is what your users will see when there is-->
                <!-- an error submitting the form-->
                <div class="d-none" id="submitErrorMessage">
                  <div class="text-center text-danger mb-3">
                    Error sending message!
                  </div>
                </div>
                <!-- Submit Button-->
                <div class="d-grid">
                  <button
                    class="btn btn-primary rounded-pill btn-lg disabled"
                    id="submitButton"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- Bootstrap core JS-->
      <!-- <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script> -->
      <!-- Core theme JS-->
      <!-- <script src="js/scripts.js"></script> -->
      <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *-->
      <!-- * *                               SB Forms JS                               * *-->
      <!-- * * Activate your form at https://startbootstrap.com/solution/contact-forms * *-->
      <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *-->
      <!-- <script src="https://cdn.startbootstrap.com/sb-forms-latest.js"></script> -->
    </body>
  <!-- </Layout> -->
</template>
<style >
  @import './styles.css';
</style>